var $ = jQuery,
	current_path = location.pathname,
	// current_host = location.origin.indexOf('devprelive.') !== -1 || location.origin.indexOf('pre') !== -1 ? '//hearingaidcomparison.com.au' : location.origin,
	current_host = location.origin.indexOf('.loc') !== -1 || location.origin.indexOf('loc.') !== -1 ? location.origin : (  location.origin.indexOf('staging.') !== -1 ? '//staging.hearingaidcomparison.com.au' : ( location.origin.indexOf('dev.') !== -1 ? '//devprelive.hearingaidcomparison.com.au' : '//hearingaidcomparison.com.au')),
	// current_host = '//hearingaidcomparison.com.au',
	search_page = current_path.indexOf('search') !== -1 ? true : false,
	current_post_params = typeof localStorage.post_params !== 'undefined' ? JSON.parse(localStorage.post_params) : {},
	search_keyword = typeof current_post_params.s !== 'undefined' ? current_post_params.s : '',
	result_data = [],
	search_html = '',
	current_search_page = typeof localStorage.current_search_page !== 'undefined' ? localStorage.current_search_page : undefined;

$.fn.serializeObject = function()
{
	var o = {};
	var a = this.serializeArray();
	$.each(a, function() {
		if (o[this.name] !== undefined) {
			if (!o[this.name].push) {
				o[this.name] = [o[this.name]];
			}
			o[this.name].push(this.value || '');
		} else {
			o[this.name] = this.value || '';
		}
  });
  return o;
};

var search_call = function(keyword, page, per_page){

	var page_count = typeof page !== 'undefined' ? parseInt(page) : 1,
		per_page = typeof per_page !== 'undefined' ? parseInt(per_page) : 10;

	var param_url = current_host + '/wp-json/wp/v2/posts?search=' + keyword + '&per_page=' + per_page;

		if(typeof page !== 'undefined')
			param_url += '&page=' + page_count;

	$.ajax({
        url: param_url,
        dataType: 'json',
        type: 'GET',
        success: function(data, textStatus, request) {
			render_result(data);
			if(parseInt(request.getResponseHeader('X-WP-TotalPages')) > 1)
				render_pagination(parseInt(request.getResponseHeader('X-WP-TotalPages')), page_count)
        },
        error: function(e) {
            console.log(e, 'error');
        }
    });

}


if(search_keyword !== '' && current_path.indexOf('search') !== -1) {

	// repeat call to get search results count for pagination
	search_call(search_keyword, current_search_page, 10);

	// search headline
	if($('.search-result-headline').length > 0)
		$('.search-result-headline').html('Search Results for: ' + search_keyword);
}

var render_pagination = function(max_pages, page_count){

	$('#result_navigation .main-blog-pagination').html('');

	var next_step = page_count + 1,
		prev_step = page_count - 1,
		click_access = true;

	if(max_pages > 1) {

		var nav_items = '',
			nav_max = max_pages,
			show_prev = true,
			generate_dot = true,
			repeatDot = false,
			step_con = 0;

		for(var x = 1; x <= nav_max; x++) {

			if(page_count > 1 && show_prev) {
				nav_items += '<li><a class="prev page-numbers" href="#" data-step="' + (page_count - 1) + '"></a></li>';
				show_prev = false;
			}

			if(page_count == x)
				nav_items += '<li><span aria-current="page" class="page-numbers current">' + x + '</span></li>';
			else
				nav_items += '<li><a class="page-numbers" href="#" data-step="' + x + '">' + x + '</a></li>';

			if(page_count < max_pages && x == nav_max)
				nav_items += '<li><a class="next page-numbers" href="#" data-step="' + (page_count + 1) + '"></a></li>';
		}

		$('#result_navigation .main-blog-pagination').html(nav_items);

		var nav_length = $('#result_navigation .main-blog-pagination').find('li').length;

		$('#result_navigation .main-blog-pagination').find('li').each(function(i){
			if(max_pages > 5) {

				if(page_count < 4) {

					if(i > page_count + 1 && i < nav_length - 3)
						$(this).remove();

					if(i == page_count + 1)
						$(this).html('<span class="page-numbers dots">…</span>');

				} else if(page_count > 3 && page_count < max_pages - 2) {

					if(i > 3 && i < nav_length - 3 && i !== page_count && i !== page_count + 1)
						$(this).remove();

					if(i == 3 || i == page_count + 1)
						$(this).html('<span class="page-numbers dots">…</span>');

				} else if(page_count == max_pages) {

					if( i > 1 && i < nav_length - 3 && i !== 2)
						$(this).remove();

					if(i == 2)
						$(this).html('<span class="page-numbers dots">…</span>');


				} else {

					if( i > 1 && i < nav_length - 4 && i !== 2)
						$(this).remove();

					if(i == 2)
						$(this).html('<span class="page-numbers dots">…</span>');
				}

			}
		});

	}

	$('#result_navigation .main-blog-pagination a').click(function(e){
		e.preventDefault();
		var link_step = parseInt($(this).data('step'));
		search_call(search_keyword, link_step, 10);
		localStorage.current_search_page = link_step;

	});
}

var render_result = function(data) {

	result_data = data;

	var search_results = $('#search-results');

	search_results.html();
	search_html = '';

	if(data.length > 0) {
		render_item(result_data[0], search_results, 0);
	}
	else {
		render_no_result();
	}
}

var date_format = function(date) {
	var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
		date_arr = date.split('T')[0].split('-');
	return months[parseInt(date_arr[1]) - 1] + ' ' + parseInt(date_arr[2]) + ', ' + date_arr[0];
}

var render_item = function(data, target, i) {

	var data_index = i++;

	if(data_index < result_data.length) {

		var result_item_el = $('.result-item-base').clone(),
			result_item_html = result_item_el.html(),
			result_item_el = result_item_el.html(result_item_html);
			result_item_el.find('article').attr('id', 'post-' + data.id).addClass('post-' + data.id);
			result_item_el.find('.entry-title').html('<a href="' + (data.link.replace(current_host, '')) + '">' + data.title.rendered + '</a>');
			result_item_el.find('.post-updated').html('Last updated on ' + date_format(data.modified));

			result_item_el.find('.result-excerpt').html(
				data.excerpt.rendered.substring(0, 150) + '...' +
				'<a href="' + (data.link.replace(current_host, '')) + '" class="read-more-btn">Read More &rarr;</a>'
			);

			if(result_item_el.find('.read-more-btn-v2').length > 0) {
				if(data.featured_media !== 0) {
					result_item_el.find('.result-img').html(
						'<img src="' + data.better_featured_image.source_url + '" class="featId-' + data.id +' img-fluid img-responsive main-img" alt="featured image">'
					);
				}
				result_item_el.find('.posted-on').html(date_format(data.date));
				result_item_el.find('.result-excerpt').html(
					data.excerpt.rendered.substring(0, 150) + '...'
				);
				result_item_el.find('.read-more-btn-v2').html('<a href="' + (data.link.replace(current_host, '')) + '" class="btn2 btn btn-arrow color1" title="">Read more</a>');
			}
			else {
				if(data.featured_media !== 0) {
					result_item_el.find('.result-img').html(
						'<img src="' + data.better_featured_image.source_url + '" class="featId-' + data.id +' blog-feat-image" alt="featured image">'
					);
				}

				result_item_el.find('.posted-on').html('<a href="' + (data.link.replace(current_host, '')) + '">' + date_format(data.date) + '</a>');
				result_item_el.find('.result-excerpt').html(
					data.excerpt.rendered.substring(0, 150) + '...' +
					'<a href="' + (data.link.replace(current_host, '')) + '" class="read-more-btn">Read More &rarr;</a>'
				);
			}


		search_html += result_item_el.html();
		render_item(result_data[i], target, i);
	} else {
		$(target).html(search_html);
		$('html, body').animate({
			'scrollTop': 0
		}, 300, 'swing');
	}

}


$(function(){

	$('form').each(function(){

		$(this).submit(function(e){

			localStorage.removeItem('post_params');
			localStorage.removeItem('current_search_page');

			var params = $(this).serializeObject(),
					formAction = $(this).attr('action').replace(/\/+$/,''),
					redirect_url = $(this).attr('id') == 'search_form' ? '/search' : formAction;
				
					localStorage.post_params = JSON.stringify(params);

			if(typeof params.postcode !== 'undefined' && redirect_url !== '/search')
				redirect_url += "?postcode=" + params.postcode;

			if(!$(this).hasClass('enable-submit')) {
				e.preventDefault();
				setTimeout(function(){
					window.location.href = redirect_url;
				}, 1000);
			}

		});

	});

	$('#leadForm').each(function(){

		$(this).submit(function(e){

			localStorage.removeItem('post_params');
			localStorage.removeItem('current_search_page');

			var params = $(this).serializeObject(),
					formAction = $(this).attr('action').replace(/\/+$/,''),
					redirect_url = $(this).attr('id') == 'search_form' ? '/search' : formAction;
				
					localStorage.post_params = JSON.stringify(params);

			if(typeof params.existing_hearing_aid !== 'undefined' && typeof params.tinnitus !== 'undefined' && typeof params.existing_private_health !== 'undefined' && typeof params.guide_age !== 'undefined' && redirect_url !== '/search')
				redirect_url += "?existing_hearing_aid=" + params.existing_hearing_aid +  "&tinnitus=" + params.tinnitus + "&existing_private_health=" + params.existing_private_health + "&guide_age=" + params.guide_age;

			if(!$(this).hasClass('enable-submit')) {
				e.preventDefault();
				setTimeout(function(){
					window.location.href = redirect_url;
				}, 1000);
			}

		});

	});

	$('.search-btn').click(function(e){
		e.preventDefault();

		$(this).parents('form').submit();
	});

	if(!$('body').hasClass('page-template-tpl_search_results-v2') && current_path.indexOf('free-hearing-test') == -1) {

		localStorage.removeItem('post_params');
		localStorage.removeItem('current_search_page');

	}

	if(current_post_params !== '')
		for(var key in current_post_params) {
			if($('input[name=' + key + ']').length !== -1)
				$('input[name=' + key + ']').val(current_post_params[key]);

			if($('select[name=' + key + ']').length !== -1)
				$('select[name=' + key + ']').val(current_post_params[key]);
		}



});
